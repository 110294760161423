h1, h2, h3 {
  @apply font-cafe font-bold;
}

h1 {
  @apply text-xl;
}

h2 {
  @apply text-lg;
}

@screen xl {
  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }
}
