@font-face {
  font-family: 'LouisGeorgeCafe';
  src: url('../fonts/LouisGeorgeCafe.woff2') format('woff2'),
  url('../fonts/LouisGeorgeCafe.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LouisGeorgeCafe';
  src: url('../fonts/LouisGeorgeCafe-Bold.woff2') format('woff2'),
  url('../fonts/LouisGeorgeCafe-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
  url('../fonts/OpenSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Bold.woff2') format('woff2'),
  url('../fonts/OpenSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
  url('../fonts/OpenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bentosa';
  src: url('../fonts/Bentosa.woff2') format('woff2'),
  url('../fonts/Bentosa.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
